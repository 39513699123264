.outer-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: row;
}

.inner-grid img {
    width: 32%;
    position: relative;
}

img {
    border-radius: 15px;
    padding: 5px;
}

img:hover {
    opacity: 0.8;
}

@media screen and (max-width: 1000px) {
    .inner-grid img {
        width: 45%;
        position: relative;
    }

}
