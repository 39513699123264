@media screen and (min-width: 1001px) {
  .header-logo-text {
    font-size: 60px;
    line-height: 60px;
  }
  .App {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

}

@media screen and (max-width: 1000px) {
  .header-logo-text {
    font-size: 30px;
    line-height: 30px;
  }
  .App {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
}

.header-logo-text {
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 10px;
  margin-top: 45px;
  margin-bottom: 20px;
}

.navbar {
  border-top: 1px solid #000;
  margin-bottom: 20px;
}

a {
  text-decoration: none !important;
  color: black;
}